//$('.parallax-window').parallax({imageSrc: '/path/to/image.jpg'});



setTimeout(function() {
	/*var randomHeightDivs = $('.random-height').length;
	console.log(randomHeightDivs);

	for(var i = 0; i < randomHeightDivs; i++) {

		$('#random-height-' + i).height(Math.floor(Math.random()*(420-250+1)+250));

	}*/

	$('.spinner').fadeOut();

},300);

setTimeout(function () {

	$('.masonry-container').masonry({
		// options
		itemSelector: '.picture-category-details',
		gutter: 10,
		isAnimated: true,
		animationOptions: {
			duration: 1500,
			easing: 'linear',
			queue: false
		}
	});

	$('.masonry-container').css('opacity', '1');


}, 1200);

$(function() {
	$('.lazy').lazy({
		effect: "fadeIn",
		effectTime: 500,
		threshold: 0
	});


	$('.mobile-nav-icon').click(function() {

		$('.nav').slideToggle();

	});

});